import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { Spin } from 'antd'

import { useStatsStore } from '@/store/stats-store'
import { getRiskColor } from '@/utils'
import { useAuthStore } from '@/store/auth-store'
import { useCompanyService } from '@/api/use-service'

interface Props {
  route: string
  text: string
  className?: string
}

export const Risk = ({ route, className, text }: Props) => {
  const { user } = useAuthStore()
  const { risk, setRisk } = useStatsStore()
  const {
    getCompanyRisk: { invoke: getRisk, isLoading: getRiskLoading },
  } = useCompanyService()

  useEffect(() => {
    const fetchRisk = async () => {
      const { data, error } = await getRisk({ pathParam: `${user?.companyIds?.[0]}/risk` })
      if (data && !error) {
        setRisk(data.risk)
      }
    }
    fetchRisk()
  }, [getRisk, setRisk, user?.companyIds])

  return (
    <Link
      to={route}
      className={`${className ?? ''} flex flex-col border-b border-l border-t border-hard-coal bg-goshawk-grey px-4 py-2 hover:bg-hard-coal`}
    >
      {getRiskLoading && <Spin />}
      {!getRiskLoading && (
        <>
          <span className={`${getRiskColor(risk)} text-[20px] font-bold capitalize leading-[20px]`}>{risk}</span>
          <span className="text-[12px] font-semibold leading-[12px]">{text}</span>
        </>
      )}
    </Link>
  )
}
