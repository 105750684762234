import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { uniqBy } from 'lodash'

import { ConversationType } from '@/api/use-service'

export interface HistoryConversation {
  title: string
  threadId: string
  additionalData?: Record<string, any>
  type: ConversationType
}

interface HistoryState {
  conversations: HistoryConversation[]
  addConversations: (conversations: HistoryConversation[]) => void
  loadConversations: (conversations: HistoryConversation[]) => void
}

export const useHistoryStore = create<HistoryState>()(
  immer(
    devtools(set => ({
      conversations: [],
      conversationsInitialized: false,
      loadConversations: conversations =>
        set(state => {
          state.conversations = uniqBy(
            [...state.conversations, ...conversations],
            c => `${c.threadId}-${c.additionalData?.categoryId ?? ''}`,
          )
        }),
      addConversations: conversations =>
        set(state => {
          state.conversations = uniqBy(
            [...conversations, ...state.conversations],
            c => `${c.threadId}-${c.additionalData?.categoryId ?? ''}`,
          )
        }),
    })),
  ),
)
