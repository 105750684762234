import { Tooltip } from 'antd'
import './index.scss'
import { useEffect, useRef, useState } from 'react'
import { capitalize } from 'lodash'

interface Props {
  title: string
  src: string
  className?: string
  navigate: () => void
}

export const HistoryItem = ({ title, navigate, src, className }: Props) => {
  const [hasOverflow, setHasOverflow] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth
      setHasOverflow(isOverflowing)
    }
  }, [title]) // Re-check when title changes

  const titleNode = (
    <span className="inline-block w-[211px] text-[13px] capitalize text-white [mask-image:linear-gradient(90deg,#000,#000_84%,transparent_100%,transparent)]">
      {title}
    </span>
  )

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      navigate()
    }
  }

  return (
    <button
      onClick={navigate}
      onKeyDown={handleKeyDown}
      className={`${className ?? ''} history-item flex cursor-pointer border-none bg-transparent text-left`}
      tabIndex={0}
    >
      <img className="h-auto w-[13px] cursor-pointer object-contain" src={src} alt="" />
      <div ref={textRef} className="ml-2 max-w-[212px] overflow-hidden whitespace-nowrap">
        {hasOverflow ? (
          <Tooltip title={capitalize(title)} placement="right">
            {titleNode}
          </Tooltip>
        ) : (
          titleNode
        )}
      </div>
    </button>
  )
}
