import React, { lazy, Suspense, FC, useCallback, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Spin } from 'antd'

import { useAuthStore } from './store/auth-store'
import { useAuthService } from './api/use-service'
import AuthLayout from './layout/AuthLayout'
import MainLayout from './layout/MainLayout'

const LoginPage = lazy(() => import('./pages/LoginPage'))
const RegisterPage = lazy(() => import('./pages/RegisterPage'))
const ForgotPage = lazy(() => import('./pages/ForgotPage'))
const RecoveryPage = lazy(() => import('./pages/RecoveryPage'))
const VerificationPage = lazy(() => import('./pages/VerificationPage'))
const CallbackPage = lazy(() => import('./pages/CallbackPage'))
const HomePage = lazy(() => import('./pages/HomePage'))
const ExpertPage = lazy(() => import('./pages/ExpertPage'))
const ExpertPlannerPage = lazy(() => import('./pages/ExpertPlannerPage'))
const CompanyPage = lazy(() => import('./pages/CompanyPage'))
const SlackCallbackPage = lazy(() => import('./pages/SlackCallbackPage'))
const MemoryPage = lazy(() => import('./pages/MemoryPage'))
const DashboardPage = lazy(() => import('./pages/DashboardPage'))
const CreateDiagnosticPage = lazy(() => import('./pages/CreateDiagnosticPage'))
const DiagnosticsPage = lazy(() => import('./pages/DiagnosticsPage'))

export enum routes {
  HOME = '/home',
  LOGIN = '/signin',
  REGISTER = '/signup',
  FORGOT = '/forgot-password',
  RECOVERY = '/password-recovery',
  VERIFICATION = '/verification',
  AUTH_CALLBACK = '/callback',
  EXPERT = '/expert',
  EXPERT_PLANNER = '/planner',
  COMPANY = '/company',
  SLACK_CALLBACK = '/slack-callback',
  MEMORY = '/memories',
  DASHBOARD = '/dashboard',
  CREATE_DIAGNOSTIC = '/new-diagnostic',
  DIAGNOSTICS = '/diagnostics',
}

export const onboardingRoutes = [
  { path: routes.LOGIN, label: 'Login', element: LoginPage },
  { path: routes.REGISTER, label: 'Register', element: RegisterPage },
  { path: routes.FORGOT, label: 'Forgot Password', element: ForgotPage },
  { path: routes.RECOVERY, label: 'Password Recovery', element: RecoveryPage },
  { path: routes.VERIFICATION, label: 'Verification', element: VerificationPage },
  { path: routes.AUTH_CALLBACK, label: 'Auth Callback', element: CallbackPage },
]
export const mainRoutes = [
  { path: routes.HOME, label: 'Home', element: HomePage },
  { path: routes.EXPERT, label: 'Expert', element: ExpertPage },
  { path: routes.EXPERT_PLANNER, label: 'Planner', element: ExpertPlannerPage },
  { path: routes.DASHBOARD, label: 'Dashboard', element: DashboardPage },
  { path: routes.CREATE_DIAGNOSTIC, label: 'Create Diagnostic', element: CreateDiagnosticPage },
  { path: routes.DIAGNOSTICS, label: 'Diagnostics', element: DiagnosticsPage },
]

export const configRoutes = [
  { path: routes.COMPANY, label: 'Company', element: CompanyPage },
  { path: routes.SLACK_CALLBACK, label: 'Slack Callback', element: SlackCallbackPage },
  { path: routes.MEMORY, label: 'Memories', element: MemoryPage },
]

const RequireAuth: FC<{ children: JSX.Element; isLogged: boolean; isLoading: boolean }> = ({
  children,
  isLogged,
  isLoading,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLogged) {
      navigate({ pathname: routes.LOGIN })
    }
  }, [isLogged, navigate])

  if (isLoading) {
    return <Spin className="flex h-full w-full items-center justify-center" />
  }

  return children
}

const RequireNonAuth: FC<{ children: JSX.Element; isLogged: boolean; isLoading: boolean }> = ({
  children,
  isLogged,
  isLoading,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isLogged) {
      navigate({ pathname: routes.HOME })
    }
  }, [isLogged, navigate])

  if (isLoading) {
    return <Spin className="flex h-full w-full items-center justify-center" />
  }

  return children
}

export const Router: FC = () => {
  const { isLogged, login } = useAuthStore()
  const { checkAuthentication } = useAuthService()
  const initialized = useRef(false)

  const checkAuth = useCallback(async () => {
    const { data, error } = await checkAuthentication.invoke()
    if (!error && data) {
      login({ ...data })
    }
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAuthentication.invoke])

  useEffect(() => {
    try {
      checkAuth()
    } finally {
      initialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (checkAuthentication.isLoading || !initialized.current) {
    return <Spin className="mt-5 flex h-full w-full items-center justify-center" />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={isLogged ? routes.HOME : routes.LOGIN} replace />} />
        {
          // Onboarding layout
        }
        <Route
          element={
            <RequireNonAuth isLogged={isLogged} isLoading={checkAuthentication.isLoading}>
              <AuthLayout
                children={
                  <Suspense fallback={<Spin className="flex h-full w-full items-center justify-center py-3" />}>
                    <Outlet />
                  </Suspense>
                }
              />
            </RequireNonAuth>
          }
        >
          {onboardingRoutes.map(({ path, element: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        {
          // Main layout
        }
        <Route
          element={
            <RequireAuth isLogged={isLogged} isLoading={checkAuthentication.isLoading}>
              <MainLayout
                isConfiguration={false}
                children={
                  <Suspense fallback={<Spin className="flex h-full w-full items-center justify-center py-3" />}>
                    <Outlet />
                  </Suspense>
                }
              />
            </RequireAuth>
          }
        >
          {mainRoutes.map(({ path, element: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        {
          // Configuration layout
        }
        <Route
          element={
            <RequireAuth isLogged={isLogged} isLoading={checkAuthentication.isLoading}>
              <MainLayout
                isConfiguration
                children={
                  <Suspense fallback={<Spin className="flex h-full w-full items-center justify-center py-3" />}>
                    <Outlet />
                  </Suspense>
                }
              />
            </RequireAuth>
          }
        >
          {configRoutes.map(({ path, element: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        <Route path="*" element={<Navigate to={isLogged ? routes.HOME : routes.LOGIN} replace />} />
      </Routes>
    </BrowserRouter>
  )
}
