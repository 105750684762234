import ReactGA from 'react-ga4'

export const trackEvent = (category: string, action: string, label?: string) => {
  try {
    if (import.meta.env.VITE_APP_NODE_ENV === 'production' && import.meta.env.VITE_REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.event({
        category,
        action,
        label,
      })
    }
  } catch (error) {
    console.error('error tracking event ', error)
  }
}

export const initializeTracking = () => {
  try {
    if (import.meta.env.VITE_APP_NODE_ENV === 'production' && import.meta.env.VITE_REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(import.meta.env.VITE_REACT_APP_GA_MEASUREMENT_ID)
    }
  } catch (error) {
    console.error('error tracking event ', error)
  }
}
