import { useEffect, useState } from 'react'
import { Button } from 'antd'
import i18n from 'i18next'
import { useNavigate } from 'react-router'

import verified from '@/assets/verified.png'
import plan from '@/assets/plan.png'
import { ConversationType, useConversationService } from '@/api/use-service'
import { useAuthStore } from '@/store/auth-store'
import { routes } from '@/router'
import { useHistoryStore } from '@/store/history-store'

import { HistoryItem } from './history-item'

interface Props {
  className?: string
}

export const ConversationHistory = ({ className }: Props) => {
  const { user } = useAuthStore()
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const { conversations, loadConversations } = useHistoryStore()
  const navigate = useNavigate()

  const {
    getAllConversations: { invoke: getAllConversations, isLoading },
  } = useConversationService()

  useEffect(() => {
    const loadConversationsPage = async (page: number) => {
      const { data, error } = await getAllConversations({
        pathParam: `${user?.companyIds?.[0]}/conversations`,
        queryParams: { page },
      })
      if (data && !error) {
        loadConversations(
          data.conversations.map(c => ({
            title: c.title,
            threadId: c.threadId,
            type: c.type,
            additionalData: c.additionalData,
          })),
        )
        setHasMore(data.hasMore)
      }
    }

    loadConversationsPage(currentPage)
  }, [currentPage, getAllConversations, loadConversations, user?.companyIds])

  const handleNavigate = (type: ConversationType, threadId: string, additionalData?: Record<string, any>) => {
    switch (type) {
      case ConversationType.EXPERT:
        navigate(`${routes.EXPERT}?t=${threadId}`)
        break
      case ConversationType.PLANNER:
        if (additionalData?.categoryId) {
          navigate(`${routes.EXPERT_PLANNER}?t=${threadId}&c=${additionalData.categoryId}`)
        }
        break
    }
  }

  return (
    <div className={className ?? ''}>
      {conversations?.map(c => (
        <HistoryItem
          key={`${c.threadId}-${c.additionalData?.categoryId}`}
          className="mb-1 ml-2"
          src={c.type === ConversationType.EXPERT ? verified : plan}
          title={c.title}
          navigate={() => handleNavigate(c.type, c.threadId, c.additionalData)}
        />
      ))}
      {hasMore && (
        <Button
          className="h-[17px] text-[13px] leading-[13px] [&>span:hover]:text-secondary [&>span]:text-smoke-screen"
          type="link"
          onClick={() => setCurrentPage(p => p + 1)}
          loading={isLoading}
        >
          {i18n.t('sidebar.more')}
        </Button>
      )}
    </div>
  )
}
