import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface User {
  id: string
  email: string
  name?: string
  companyIds: string[]
}

interface AuthState {
  isLogged: boolean
  message: string
  user?: User
  login: (user: User) => void
  logout: (message?: string) => void
}

export const useAuthStore = create<AuthState>()(
  immer(
    devtools(set => ({
      isLogged: false,
      message: '',
      user: undefined,
      login: (user: User) => set({ isLogged: true, user }),
      logout: (message?: string) => set({ isLogged: false, message: message ?? '', user: undefined }),
    })),
  ),
)
