import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'
import i18n from 'i18next'

import { useTitleStore } from '@/store/title-store'
import { trackEvent } from '@/utils/analytics'
import { routes } from '@/router'

import { Sidebar } from './components/sidebar'

import { Avatar } from '../components/avatar'
import { Risk } from '../components/risk'

import './MainLayout.scss'

interface MainLayoutProps {
  children: ReactNode
  withTitle?: boolean
  isConfiguration?: boolean
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, withTitle = true, isConfiguration = false }) => {
  const { title } = useTitleStore()
  const location = useLocation()

  useEffect(() => {
    trackEvent('MainLayout', 'PageView', location.pathname)
  }, [location, isConfiguration])

  return (
    <div id="main-layout" className={isConfiguration ? 'configuration-mode' : ''}>
      <Sidebar />
      <div className="main-content">
        <nav className="navbar flex justify-between">
          <div className="breadcrumb flex-1" />
          {withTitle && <div className="title mx-auto flex items-center justify-center">{title ?? null}</div>}
          <div className="menu flex flex-1 items-center justify-end pl-5">
            <Avatar className="mr-5" />
            {!isConfiguration && <Risk text={i18n.t('sidebar.risk')} route={routes.DASHBOARD} />}
          </div>
        </nav>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default MainLayout
