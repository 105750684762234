import { SyntheticEvent } from 'react'
import './index.scss'

interface Props {
  onClick: () => void
  title: string
  src: string
  className?: string
}

export const MenuItem = ({ title, onClick, src, className }: Props) => {
  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault()
    onClick()
  }

  return (
    <a href="#" onClick={handleClick} className={`${className ?? ''} menu-item flex`}>
      <img className="h-auto w-[15px] cursor-pointer object-contain" src={src} />
      <span className="ml-3 text-[15px] font-semibold">{title}</span>
    </a>
  )
}
