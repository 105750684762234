import { format } from 'date-fns'

import { MemoryContext, Risk, Vulnerability } from '@/api/use-service'

export const getElementById = (id: string) => document.getElementById(id)!

export const INVITATION_CODE_KEY = 'invitation-code'

const keyBefore = 'secura-platform-'

export const setLocalStorage = (key: string, value: any): void => {
  value && localStorage.setItem(`${keyBefore}${key}`, typeof value === 'string' ? value : JSON.stringify(value))
}

export const getLocalStorage = <T>(key: string, isParse = false): T => {
  const cache = localStorage.getItem(`${keyBefore}${key}`) ?? ''
  return isParse ? JSON.parse(cache) : cache
}

export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(`${keyBefore}${key}`)
}

export const setSessionStorage = (key: string, value: any): void => {
  value && sessionStorage.setItem(`${keyBefore}${key}`, typeof value === 'string' ? value : JSON.stringify(value))
}

export const getSessionStorage = <T>(key: string, isParse = false): T => {
  const cache = sessionStorage.getItem(`${keyBefore}${key}`) ?? ''
  return isParse ? JSON.parse(cache) : cache
}

export const getRiskColor = (risk: Risk) => {
  switch (risk) {
    case 'low':
      return 'text-cornflower-blue'
    case 'medium':
      return 'text-vegas-gold'
    case 'high':
      return 'text-indian-red'
    default:
      return 'text-apple'
  }
}

export const getRiskTagColor = (risk: Risk) => {
  switch (risk) {
    case 'low':
      return '#6592EC'
    case 'medium':
      return '#CDBD53'
    case 'high':
      return '#DC6161'
    default:
      return '#66AA4A'
  }
}

export const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email)

export const formatContext = (context: MemoryContext) => {
  return `${context.message} - by ${context.email} - at ${format(new Date(context.createdAt), 'dd/MM/yyyy')}`
}

export const severityOrder: Record<string, number> = { low: 0, medium: 1, high: 2 }

export const sortVulnerabiltiies = (vulnerabilities: Vulnerability[] | undefined) =>
  vulnerabilities
    ? [...vulnerabilities].sort((a, b) => {
        // Sort by risk level
        const riskA = severityOrder[a.risk.toLowerCase()] ?? 0
        const riskB = severityOrder[b.risk.toLowerCase()] ?? 0
        if (riskA !== riskB) {
          return riskB - riskA
        }

        // Sort by date (most recent first)
        const timeDIff = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        if (timeDIff !== 0) return timeDIff

        return a.description.localeCompare(b.description)
      })
    : []
