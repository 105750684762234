export const resources = {
  en: {
    translation: {
      login: {
        title: 'Authentication',
        legend: "Don't have an account?",
        email_label: 'Email',
        email_required: 'Please input your email',
        email_placeholder: 'Email...',
        password_label: 'Password',
        password_required: 'Please input your password',
        password_placeholder: 'Password...',
        register: 'Register',
        submit: 'Authenticate',
        google_btn: 'Continue with Google',
        forgot: 'Forgot password',
      },
      forgot: {
        title: 'Forgot password',
        legend: 'Enter your email address and we will send you instructions to reset your password.',
        email_label: 'Email',
        email_required: 'Please input your email',
        email_placeholder: 'Email...',
        submit: 'Send',
      },
      password_bar: {
        bar_level_1: 'weak password',
        bar_level_2: 'weak password',
        bar_level_3: 'okay password',
        bar_level_4: 'good password',
        bar_level_5: 'strong password',
        password_must: 'Password must contain at least',
        must_characters: '12 character',
        must_uppercase: '1 uppercase',
        must_lowercase: '1 lowercase',
        must_number: '1 number',
        must_special: '1 special character e.g. !_@',
      },
      register: {
        title: 'Register',
        legend: 'Already have an account?',
        signin: 'Authenticate',
        submit: 'Register',
        name_required: 'Please input your name',
        name_label: 'Name',
        name_placeholder: 'Name...',
        email_required: 'Please input your email',
        email_not_valid: 'Email is not valid',
        email_label: 'Email',
        email_placeholder: 'Email...',
        password_label: 'Password',
        password_placeholder: 'Password...',
        password_required: 'Please input your password',
        password_whitespace: 'Password cannot have leading or trailing spaces',
        confirmation_required: 'Please confirm your password',
        confirmation_not_match: "Password confirmation doesn't match",
        confirmation_label: 'Confirm password',
        confirmation_placeholder: 'Password confirmation...',
        google_btn: 'Continue with Google',
        invitation_validating: 'Validating invitation code...',
        invitation_invalid: 'Invalid or expired invitation code.',
        invitation_valid: "You've been invited to join {0}.",
        invitation_validating_message: 'We are validating your invitation code. Please wait a moment...',
        invitation_invalid_message:
          'Your invitation code is invalid or has expired. Please contact your administrator.',
        invitation_valid_message: "You've been invited to join {0}. Please complete your registration below.",
        invitation_valid_message_part1: "You've been invited to join",
        invitation_valid_message_part2: '. Please complete your registration below.',
      },
      recovery: {
        title: 'Password Recovery',
        legend: "We've sent a verification code to your email. Please enter it below.",
        submit: 'Change',
        password_required: 'Please input your new password',
        new_password_label: 'New password',
        new_password_placeholder: 'Password...',
        confirmation_required: 'Please confirm your new password',
        confirmation_not_match: "Password confirmation doesn't match",
        confirmation_label: 'Confirm new password',
        confirmation_placeholder: 'Password confirmation...',
        title_verified: 'Password Changed! 🎉',
        legend_verified: 'You can now login.',
        login: 'Go to Login',
        resend: 'Resend Code',
      },
      verification: {
        title: 'Verification Code',
        legend: "We've sent a verification code to your email. Please enter it below.",
        title_verified: 'Code Verified! 🎉',
        legend_verified: 'You can now login.',
        login: 'Go to Login',
        resend: 'Resend Code',
      },
      callback: {
        title: 'Authenticating...',
        legend: 'Please wait while we verify your credentials. You will be redirected shortly.',
        login: 'Back to Login',
      },
      slack_callback: {
        title: 'Verifying...',
        legend: 'Please wait while we verify the slack installation. You will be redirected shortly.',
        company: 'Back to Company',
      },
      sidebar: {
        home: 'Home',
        expert: 'Expert',
        diagnostics: 'Diagnostics',
        help: 'Help',
        history: 'History',
        dashboard: 'Dashboard',
        company: 'Company',
        memories: 'Memories',
        profile: 'Profile',
        settings: 'Settings',
        logout: 'Logout',
        risk: 'Risk',
        more: 'more',
      },
      home: {
        title: 'Home',
        welcome_1: 'Welcome,',
        welcome_2: 'my name is',
        welcome_3: 'Secura',
        welcome_4: 'your Cybersecurity Assistant.',
        help_message: 'How can I help you today',
        expert: 'Expert',
        expert_text: 'I have a question about cybersecurity and need an expert.',
        diagnostic: 'Diagnostic',
        diagnostic_text: 'I want to conduct a vulnerability assessment for my company.',
        help: 'Help',
        help_text: 'I have a question about Secura Platform.',
        security_message: 'All the information will be protected and secured.',
        dashboard_text: 'I want to see the cybersecurity dashboard.',
        dashboard: 'Dashboard',
        onboarding: 'Onboarding',
        onboarding_text: "Click here to go to your company's profile.",
        onboarding_message: "Let's start by setting up your company",
      },
      company: {
        title: 'Company',
        section_title: 'Manage Company',
        manage_memory: 'manage memory',
        active_users: 'Active Users',
        column_name: 'Name',
        column_email: 'Email',
        column_client: 'Client',
        search_placeholder: 'Search...',
        invite_admin: 'Invite Admin',
        invite_admin_title: 'Invite Admin to {{companyName}}',
        user_email: 'Admin Email',
        email_required: 'Please input the email of the admin',
      },
      memory: {
        title: 'Memories',
        section_title: 'Manage Memories',
        column_date: 'Date',
        column_description: 'Description',
        column_context: 'Context',
        column_category: 'Category',
        column_type: 'Type',
        search_placeholder: 'Search...',
        column_actions: 'Actions',
        delete_title: 'Delete the memory',
        delete_description: 'Are you sure to delete this memory?',
        delete_yes: 'Yes',
        delete_no: 'No',
        context_title: 'Context',
        empty_memories: 'No Memories',
        delete_tooltip: 'Delete Memory',
        wrong_tooltip: 'Mark Memory as Wrong',
        mark_wrong_title: 'Mark the memory as Wrong',
        mark_wrong_description: 'Are you sure to mark this memory as wrong?',
        mark_wrong_yes: 'Yes',
        mark_wrong_no: 'No',
      },
      chat: {
        title: 'Expert',
        textarea_placeholder: 'Your message...',
        btn_send: 'Send Message',
        greeting: `Hello **{{name}}**  
I want you to know that everything we discuss will be protected and secure.  
Do you need assistance?`,
      },
      dashboard: {
        title: 'Dashboard',
        origin_title: 'Origin',
        explanation_title: 'Explanation',
        mark_as: 'Mark as {{done}}',
        analyzing: 'Analyzing',
        mitigation_plan: 'Mitigation Plan',
        explanation: 'Explanation',
        origin: 'Origin',
        description: 'Description',
        categories_title: 'Categories & Vulnerabilities ({{qty}})',
        analyzed: '# of Analysis',
        fixed_qty: '# of Fixed',
        found: '# of Found',
        risk: 'Risk',
        fixed: 'Fixed',
        not_fixed: 'Not Fixed',
        suggestions_title: 'Generating...',
        suggestions_legend: 'Please wait while we generate your custom suggestions.',
        no_vulnerabilities: 'No vulnerabilities found',
        delete_title: 'Delete the vulnerability',
        delete_content: 'Are you sure to delete this vulnerability?',
        delete_ok: 'Yes',
        delete_cancel: 'No',
        delete: 'Delete',
        mark_fixed: 'Mark as Fixed',
        mark_not_fixed: 'Mark as Not Fixed',
        description_title: 'Description',
        suggestions_actions_title: 'Suggested Actions',
        action: 'Action',
        empty_vulnerabilities: 'No Vulnerabilities',
        new_diagnostic: '+ Diagnostic',
      },
      expert_planner: {
        title: 'Planner',
        plan_title: 'Generating...',
        plan_legend: 'Please wait while we generate your custom mitigation plan.',
        actions: 'Actions',
        actions_title: 'Suggested Actions',
        vulnerabilities_title: 'Vulnerabilties',
        description: 'Description',
        description_title: 'Description',
        category: 'Category',
        not_found_title: 'Vulnerability Not Found',
        not_found_legend: 'Sorry, the vulnerability was not found or previously deleted.',
        action: 'Action',
        new: 'New',
        regenerate: 'Regenerate Plan',
        regenerate_title: 'Regenerate Plan',
        regenerate_content: 'Are you sure to regenerate this plan?',
        regenerate_ok: 'Yes',
        regenerate_cancel: 'No',
        outdated: 'Outdated',
        outdated_tooltip: 'This plan was regenerated',
      },
      create_diagnostic: {
        title: 'Diagnostic',
        legend:
          'With Diagnostic, just enter a cybersecurity topic and the emails of the team members you want to involve. The system will automatically start a conversation with those users, collect their input, and analyze the responses. ',
        client_label: 'Select where to start the conversation.',
        emails_label: 'Emails of the users you want to collect input.',
        emails_required: 'Please input the emails of the users',
        emails_placeholder: 'Emails separated by space...',
        emails_invalid: 'Invalid Emails',
        topic_label: 'Topic you want Secura to discuss. E.g. Passwords Security, Phishing, etc.',
        topic_required: 'Please input the title of the topic',
        topic_placeholder: 'Title of the topic (one topic per diagnostic)...',
        additional_label: 'Additional information about the Security topic you want to analyze. (Optional)',
        additional_required: 'Please input the topic',
        additional_placeholder: 'About the topic or desirable outcomes of the conversation...',
        submit: 'Create Diagnostic',
      },
      diagnostic: {
        title: 'Diagnostic',
        legend:
          'With Diagnostic, just enter a cybersecurity topic and the emails of the team members you want to involve. The system will automatically start a conversation with those users, collect their input, and analyze the responses. ',
        diagnostic_title: 'Diagnostics ({{qty}})',
        no_diagnostics: 'No diagnostics found',
        users_title: 'Users',
        additional_information_title_link: 'Additionals',
        additional_information_title: 'Additional Information',
        finished: 'Finished',
        started: 'Started',
        not_started: 'Not Started',
        not_found: 'Not Found',
        finish: 'Finish',
        start: 'Start',
        results: 'Results',
        add_diagnostic: '+ New',
        started_at: 'Started at {{date}}',
        finished_at: 'Finished at {{date}}',
        results_title: 'Diagnostic Insights',
        results_loading_title: 'Generating...',
        results_loading_legend: 'Please wait while we analyze the results for the diagnostic.',
        delete_title: 'Delete the diagnostic',
        delete_content: 'Are you sure to delete this diagnostic?',
        delete_ok: 'Yes',
        delete_cancel: 'No',
      },
      data_table: {
        search_placeholder: 'Search...',
      },
    },
  },
}
