import { useDeleteApiService, useGetApiService, usePatchApiService, usePostApiService } from './use-api-service'
import { useStreamService } from './use-stream-service'

export const AUTH_PATH = 'auth'
const COMPANY_PATH = 'company'
const SLACK_PATH = 'slack'
export const LOGOUT_RESOURCE = 'logout'

export interface RegisterPayload {
  name: string
  email: string
  password: string
  code?: string
}

export interface VerificationPayload {
  confirmationCode: string
  email: string
}

export interface LoginPayload {
  email: string
  password: string
}

export interface ForgotPayload {
  email: string
}

export interface GoogleLoginPayload {
  code: string
  invitationCode?: string
}

export interface ChatPayload {
  message: string
  threadId?: string
}

export interface RecoverPayload {
  confirmationCode: string
  email: string
  newPassword: string
}

export interface MessageResponse {
  message: string
}

export interface LoginResponse extends MessageResponse {
  name: string
  email: string
  id: string
  companyIds: string[]
}

export interface AuthResponse extends MessageResponse {
  name: string
  email: string
  id: string
  companyIds: string[]
}

export enum ClientType {
  slack = 'slack',
  web = 'web',
}

export interface ActiveUser {
  email: string
  name: string
  blocked: boolean
  client: ClientType
}

export interface Company {
  id: string
  name: string
  employees: number
  industry: string
  activeUsers: ActiveUser[]
  slackInstallationId: string
}

export interface GetCompanyResponse extends MessageResponse {
  company: Company
  slackInstallUrl: string
}

export enum MemoryType {
  vulnerability = 'vulnerability',
  information = 'information',
  process = 'process',
  infraestructure = 'infraestructure',
  securityEvent = 'security-event',
}

export type MemoryContext = {
  message: string
  createdAt: Date
  email: string
  name: string
}

export interface Memory {
  id: string
  description: string
  createdAt: string
  category: string
  type: MemoryType
  context: MemoryContext[]
  count: number
}

export type Risk = 'low' | 'medium' | 'high'

export interface Vulnerability {
  id: string
  description: string
  createdAt: string
  risk: Risk
  explanation: string
  done: boolean
  context: MemoryContext[]
  count: number
}

export interface CategorizedVulnerabilities {
  id: string
  name: string
  description: string
  count: number
  risk: Risk
  vulnerabilities: Vulnerability[]
  suggestedActions: { id: string; action: string; done: boolean }[]
  mitigationPlan: string
}

export interface Stats {
  fixed: number
  notFixed: number
  risk: Risk
  analizedQty: number
  categorizedVulnerabilities: CategorizedVulnerabilities[]
}

export interface CategoryPlanResponse extends MessageResponse {
  plan: {
    id: string
    name: string
    risk: Risk
    description: string
    mitigationPlan: string
    suggestedActions: [{ id: string; action: string; done: boolean }]
    vulnerabilities: Vulnerability[]
    lastPlanVulnerabilitesIds: string[]
  }
}

export interface StatsResponse extends MessageResponse {
  stats: Stats
}

export interface GetMemoriesResponse extends MessageResponse {
  memories: Memory[]
}

export interface GetRiskResponse extends MessageResponse {
  risk: Risk
}

export interface CreateDiagnosticPayload {
  topic: string
  additionalInformation: string
  emails: string[]
}

export interface CreateDiagnosticResponse extends MessageResponse {
  id: string
}

export enum DiagnosticStatus {
  started = 'started',
  notStarted = 'notStarted',
  finished = 'finished',
}

export enum DiagnosticConversationStatus {
  started = 'started',
  notStarted = 'notStarted',
  finished = 'finished',
  notFound = 'notFound',
}

export interface Diagnostic {
  id: string
  topic: string
  additionalInformation: string
  emails: string[]
  client: ClientType
  status: DiagnosticStatus
  results: string
  createdAt: string
  startedAt: string
  finishedAt: string
  conversations: {
    id: string
    threadId: string
    conversationId: string
    email: string
    status: DiagnosticConversationStatus
    results: string
    startedAt: string
    finishedAt: string
  }[]
}

export interface GetDiagnosticResponse extends MessageResponse {
  diagnostics: Diagnostic[]
}

export interface ConversationInsight {
  id: string
  insights: string
}

export interface GetDiagnosticResultsResponse extends MessageResponse {
  insights: {
    diagnosticInsights: string
    conversationsInsights: ConversationInsight[]
  }
}

export interface ToggleVullnerabilityResponse extends MessageResponse {
  avgRisk: Risk
  categoryAvgRisk: Risk
}

export interface DeleteCompanyMemoryResponse extends MessageResponse {
  avgRisk: Risk
  categoryAvgRisk: Risk
}

export interface MarkMemoryAsWrongResponse extends MessageResponse {
  avgRisk: Risk
}

export enum ConversationType {
  EXPERT = 'Expert',
  PLANNER = 'Planner',
  DIAGNOSTIC = 'Diagnostic',
}

export interface GetAllConversation {
  threadId: string
  title: string
  type: ConversationType
  createdAt: string
  additionalData: Record<string, any>
}

export interface GetAllConversationsResponse extends MessageResponse {
  hasMore: boolean
  conversations: GetAllConversation[]
}

export enum MessageSender {
  USER = 'User',
  AI = 'AI',
}

export interface GetConversation {
  threadId: string
  messages: { sender: MessageSender; text: string; createdAt: Date }[]
  additionalData: Record<string, any>
}

export interface GetConversationsResponse extends MessageResponse {
  conversation: GetConversation
}

export interface HasCompanySetupResponse extends MessageResponse {
  hasSetup: boolean
}

export interface InvitationCreatePayload {
  email: string
  companyId: string
}

export interface InvitationValidateResponse extends MessageResponse {
  validation: {
    isValid: boolean
    email: string
    companyId: string
    companyName: string
  }
}

export interface RegisterWithInvitationPayload extends RegisterPayload {
  code: string
}

export const useOboardingService = () => {
  const register = usePostApiService<RegisterPayload, MessageResponse>(AUTH_PATH, 'signup', false)
  const verification = usePostApiService<VerificationPayload, MessageResponse>(AUTH_PATH, 'verify', false)
  const login = usePostApiService<LoginPayload, LoginResponse>(AUTH_PATH, 'signin', false)
  const recovery = usePostApiService<RecoverPayload, MessageResponse>(AUTH_PATH, 'password-recovery', false)
  const forgot = usePostApiService<ForgotPayload, MessageResponse>(AUTH_PATH, 'forgot-password', false)
  const googleLogin = usePostApiService<GoogleLoginPayload, LoginResponse>(AUTH_PATH, 'google-authentication', false)
  const inviteUser = usePostApiService<InvitationCreatePayload, MessageResponse>(AUTH_PATH, 'invite')
  const validateInvitationCode = useGetApiService<any, InvitationValidateResponse>({
    path: AUTH_PATH,
    resource: 'validate-invitation',
    key: 'validate-invitation',
    withAuth: false,
  })

  return {
    register,
    verification,
    login,
    forgot,
    recovery,
    googleLogin,
    validateInvitationCode,
    inviteUser,
  }
}

export const useAuthService = () => {
  const logout = useGetApiService<any, MessageResponse>({
    path: AUTH_PATH,
    resource: LOGOUT_RESOURCE,
    key: 'logout',
    withAuth: false,
  })
  const checkAuthentication = useGetApiService<any, AuthResponse>({
    path: AUTH_PATH,
    resource: 'check-authentication',
    key: 'check-authentication',
    withAuth: false,
  })

  return { checkAuthentication, logout }
}

export const useSlackService = () => {
  const callback = useGetApiService<any, MessageResponse>({ path: SLACK_PATH, resource: 'callback', key: 'callback' })

  return { callback }
}

export const useChatService = () => {
  const chatStream = useStreamService<ChatPayload>(COMPANY_PATH)
  const plannerStream = useStreamService<ChatPayload>(COMPANY_PATH)

  return { chatStream, plannerStream }
}

export const useCompanyService = () => {
  const updateCompany = usePatchApiService<any, MessageResponse>(COMPANY_PATH)
  const deleteCompanyMemory = useDeleteApiService<any, DeleteCompanyMemoryResponse>(COMPANY_PATH)
  const markMemoryAsWrong = usePatchApiService<any, MarkMemoryAsWrongResponse>(COMPANY_PATH)
  const toggleVulnerability = usePatchApiService<any, ToggleVullnerabilityResponse>(COMPANY_PATH)
  const toggleCategorySuggestion = usePatchApiService<any, MessageResponse>(COMPANY_PATH)
  const regenerateCategoryPlanAndSuggestions = usePatchApiService<any, CategoryPlanResponse>(COMPANY_PATH)
  const getCompany = useGetApiService<any, GetCompanyResponse>({ path: COMPANY_PATH, key: 'getCompany' })
  const hasCompanySetup = useGetApiService<any, HasCompanySetupResponse>({ path: COMPANY_PATH, key: 'check-setup' })
  const getCompanyMemories = useGetApiService<any, GetMemoriesResponse>({
    path: COMPANY_PATH,
    key: 'getCompanyMemories',
  })
  const getCompanyRisk = useGetApiService<any, GetRiskResponse>({
    path: COMPANY_PATH,
    key: 'getCompanyRisk',
  })
  const getCategoryPlanAndSuggestions = useGetApiService<any, CategoryPlanResponse>({
    path: COMPANY_PATH,
    key: 'getCategoryPlanAndSuggestions',
  })
  const getCompanyCategoryStats = useGetApiService<any, StatsResponse>({
    path: COMPANY_PATH,
    key: 'getCompanyCategoryStats',
  })

  return {
    getCompany,
    hasCompanySetup,
    updateCompany,
    getCompanyMemories,
    deleteCompanyMemory,
    getCategoryPlanAndSuggestions,
    regenerateCategoryPlanAndSuggestions,
    getCompanyCategoryStats,
    toggleVulnerability,
    toggleCategorySuggestion,
    getCompanyRisk,
    markMemoryAsWrong,
  }
}

export const useCompanyDiagnosticService = () => {
  const createDiagnostic = usePostApiService<CreateDiagnosticPayload, CreateDiagnosticResponse>(COMPANY_PATH)
  const toggleDiagnostic = usePatchApiService<any, MessageResponse>(COMPANY_PATH)
  const getCompanyDiagnostics = useGetApiService<any, GetDiagnosticResponse>({
    path: COMPANY_PATH,
    key: 'getCompanyDiagnostics',
  })
  const getDiagnosticResults = useGetApiService<any, GetDiagnosticResultsResponse>({
    path: COMPANY_PATH,
    key: 'getDiagnosticResults',
  })
  const deleteDiagnostic = useDeleteApiService<any, MessageResponse>(COMPANY_PATH)

  return {
    createDiagnostic,
    getCompanyDiagnostics,
    toggleDiagnostic,
    getDiagnosticResults,
    deleteDiagnostic,
  }
}

export const useConversationService = () => {
  const getAllConversations = useGetApiService<any, GetAllConversationsResponse>({
    path: COMPANY_PATH,
    key: 'getAllConversations',
  })
  const getConversationByThreadId = useGetApiService<any, GetConversationsResponse>({
    path: COMPANY_PATH,
    key: 'getConversationByThreadId',
  })

  return { getAllConversations, getConversationByThreadId }
}
