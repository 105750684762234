import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'

import { trackEvent } from '@/utils/analytics'

import { AuthMain } from './components/AuthMain'

interface AuthLayoutProps {
  children: ReactNode
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    trackEvent('AuthLayout', 'PageView', location.pathname)
  }, [location])

  return <AuthMain>{children}</AuthMain>
}

export default AuthLayout
