import { useCallback, useMemo, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { api, authenticatedApi } from './api'

interface ServiceError {
  message: string
}

interface InvokeParams<P> {
  payload?: P
  pathParam?: string
}

const useApiServiceMutation = <P, R>(
  method: 'post' | 'patch' | 'put' | 'delete',
  path: string,
  resource?: string,
  withAuth = true,
) => {
  const pathParamRef = useRef<string | undefined>(undefined)

  const mutation = useMutation<R, AxiosError<ServiceError>, P>(
    async payload => {
      const fetcher = withAuth ? authenticatedApi : api
      const url = `/${path}${resource ? `/${resource}` : ''}${pathParamRef.current ? `/${pathParamRef.current}` : ''}`
      const res = method === 'delete' ? await fetcher[method](url) : await fetcher[method](url, payload)
      return res.data
    },
    { retry: false },
  )

  const invoke = useCallback(
    async ({
      payload,
      pathParam,
    }: InvokeParams<P>): Promise<{
      data?: R
      error?: ServiceError
      status?: number
    }> => {
      pathParamRef.current = pathParam
      try {
        const data = await mutation.mutateAsync(payload as P)
        return { data, error: undefined, status: 200 }
      } catch (error) {
        const err = error as AxiosError<ServiceError>
        return { data: undefined, error: err.response?.data, status: err.status }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.mutateAsync],
  )

  return useMemo(
    () => ({ invoke, isLoading: mutation.isLoading, error: mutation.error?.response?.data, data: mutation.data }),
    [invoke, mutation.data, mutation.error?.response?.data, mutation.isLoading],
  )
}

export const usePatchApiService = <P, R>(path: string, resource?: string, withAuth = true) => {
  return useApiServiceMutation<P, R>('patch', path, resource, withAuth)
}

export const usePutApiService = <P, R>(path: string, resource?: string, withAuth = true) => {
  return useApiServiceMutation<P, R>('put', path, resource, withAuth)
}

export const usePostApiService = <P, R>(path: string, resource?: string, withAuth = true) => {
  return useApiServiceMutation<P, R>('post', path, resource, withAuth)
}

export const useDeleteApiService = <P, R>(path: string, resource?: string, withAuth = true) => {
  return useApiServiceMutation<P, R>('delete', path, resource, withAuth)
}

interface GetInvokeParams<P> {
  queryParams?: P
  pathParam?: string
}

export const useGetApiService = <P extends Record<string, any> | undefined, R>({
  path,
  resource,
  key,
  withAuth = true,
}: {
  path: string
  resource?: string
  key: string
  withAuth?: boolean
}) => {
  const queryParamsRef = useRef<P | undefined>(undefined) // Ref to store the latest query params
  const pathParamRef = useRef<string | undefined>(undefined) // Ref to store the latest path params

  const queryKey = [path, key]

  // TODO: refactor this to send an array of resources
  const fetcher = useCallback(async (): Promise<R> => {
    const fetchInstance = withAuth ? authenticatedApi : api
    const url = `/${path}${resource ? `/${resource}` : ''}${pathParamRef.current ? `/${pathParamRef.current}` : ''}`
    const response = await fetchInstance.get(url, { params: queryParamsRef.current || {} })
    return response.data
  }, [path, resource, withAuth])

  const { data, error, isFetching, refetch } = useQuery<R, AxiosError<ServiceError>>(queryKey, fetcher, {
    enabled: false, // Prevent automatic fetching
    staleTime: Infinity,
    retry: false,
  })

  const invoke = useCallback(
    async ({ queryParams, pathParam }: GetInvokeParams<P> | undefined = {}): Promise<{
      data?: R
      error?: ServiceError
      status?: number
    }> => {
      queryParamsRef.current = queryParams // Update the ref with the latest query params
      pathParamRef.current = pathParam // Update the ref with the latest path param
      try {
        const result = await refetch() // Fetch data with the latest params in the ref
        return { data: result.data, error: result.error?.response?.data, status: Number(result.error?.status) }
      } catch (error) {
        const err = error as AxiosError<ServiceError>
        return { data: undefined, error: err, status: Number(err.status) }
      }
    },
    [refetch],
  )

  return useMemo(() => ({ invoke, isLoading: isFetching, error, data }), [data, error, invoke, isFetching])
}
