import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'

import { CategorizedVulnerabilities, Risk, Stats } from '@/api/use-service'

export interface SuggestedAction {
  id: string
  action: string
  done: boolean
}

interface StatsState {
  fixed: number
  notFixed: number
  risk: Risk
  analizedQty: number
  categorizedVulnerabilities: CategorizedVulnerabilities[]
  setStats: (stats: Stats | undefined) => void
  toggleVulnerability: (categoryId: string, memoryId: string) => void
  toggleSuggestion: (categoryId: string, suggestionId: string) => void
  setSuggestedActions: (categoryId: string, suggestedActions: SuggestedAction[]) => void
  setMitigationPlan: (categoryId: string, mitigationPlan: string) => void
  setRisk: (risk: Risk) => void
  setCategoryRisk: (cateogryId: string, risk: Risk) => void
  deleteVulnerability: (categoryId: string, memoryId: string) => void
}

export const useStatsStore = create<StatsState>()(
  immer(
    devtools(set => ({
      fixed: 0,
      notFixed: 0,
      risk: 'low',
      analizedQty: 0,
      categorizedVulnerabilities: [],
      setStats: (stats: Stats | undefined) => {
        set({ ...stats })
      },
      setRisk: (risk: Risk) =>
        set(state => {
          state.risk = risk
        }),
      setCategoryRisk: (categoryId: string, risk: Risk) =>
        set(state => {
          const category = state.categorizedVulnerabilities.find(c => c.id === categoryId)
          if (category) {
            category.risk = risk
          }
        }),
      toggleVulnerability: (categoryId: string, memoryId: string) =>
        set(state => {
          const memory = state.categorizedVulnerabilities
            .find(c => c.id === categoryId)
            ?.vulnerabilities.find(v => v.id === memoryId)
          if (memory) {
            memory.done = !memory.done
            if (memory.done) {
              state.fixed++
              state.notFixed--
            } else {
              state.fixed--
              state.notFixed++
            }
          }
        }),
      toggleSuggestion: (categoryId: string, suggestionId: string) =>
        set(state => {
          const category = state.categorizedVulnerabilities.find(c => c.id === categoryId)
          if (category) {
            const suggestion = category.suggestedActions.find(s => s.id === suggestionId)
            if (suggestion) {
              suggestion.done = !suggestion.done
            }
          }
        }),
      setSuggestedActions: (categoryId: string, suggestedActions: SuggestedAction[]) =>
        set(state => {
          const category = state.categorizedVulnerabilities.find(c => c.id === categoryId)
          if (category) {
            category.suggestedActions = suggestedActions
          }
        }),
      setMitigationPlan: (categoryId: string, mitigationPlan: string) =>
        set(state => {
          const category = state.categorizedVulnerabilities.find(c => c.id === categoryId)
          if (category) {
            category.mitigationPlan = mitigationPlan
          }
        }),
      deleteVulnerability: (categoryId: string, memoryId: string) =>
        set(state => {
          state.categorizedVulnerabilities = state.categorizedVulnerabilities.map(c => {
            if (c.id === categoryId) {
              const memory = c.vulnerabilities.find(v => v.id === memoryId)
              c.vulnerabilities = c.vulnerabilities.filter(v => v.id !== memoryId)
              if (memory?.done) {
                state.fixed--
              } else {
                state.notFixed--
              }
            }
            return c
          })
        }),
    })),
  ),
)
